import Container from "common/components/Container";
import DefaultLayout from "common/layouts/Default";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <Container>
        <div className="text-center">
          <h1 className="mt-8 mb-4 text-xl md:text-2xl">
            Oops! Taka strona nie istnieje 🙊
          </h1>
          <GatsbyLink to="/" className="text-lg hover:text-zinc-300 md:text-xl">
            Powrót na stronę główną 🏠
          </GatsbyLink>
        </div>
      </Container>
    </DefaultLayout>
  );
};

export default NotFoundPage;
