import CookieConsent from "common/components/CookieConsent";
import React from "react";

import Footer from "./Footer";
import NavBar from "./NavBar";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <div className="overflow-hidden">
      <div className="mb-2 lg:mb-4">
        <NavBar />
      </div>

      <main className="min-h-screen">{children}</main>

      <div className="mt-2 bg-black">
        <Footer />
      </div>

      <CookieConsent />
    </div>
  );
};

export default DefaultLayout;
