import { Link as GatsbyLink } from "gatsby";
import React from "react";

import logo from "../../../../static/logo.svg";

const NavBar: React.FC = () => {
  return (
    <nav className="flex justify-center bg-black py-4">
      <GatsbyLink to="/">
        <img
          src={logo}
          alt="Logo Piano4U"
          width={180}
          className="w-[140px] lg:w-[180px]"
        />
      </GatsbyLink>
    </nav>
  );
};

export default NavBar;
