import Container from "common/components/Container";
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer>
      <Container customClasses="py-20 text-zinc-400 flex flex-col lg:flex-row lg:justify-between gap-2 lg:items-center">
        <p className="font-semibold">
          &copy; {new Date().getFullYear()} Piano4U
        </p>
        <p className="text-xs">
          Designed & Created by{" "}
          <a href="https://github.com/fostrwski" target="blank">
            Franek Ostrowski
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
