import { Link } from "gatsby";
import React from "react";

interface ButtonProps {
  type?: string;
  customClasses?: string;
  href?: string;
  to?: string;
  children: React.ReactNode;
  text?: boolean;
  icon?: boolean;
  outlined?: boolean;
}

const createButtonClassName = (
  customClasses: string,
  text: boolean,
  icon: boolean,
  outlined: boolean
) =>
  `w-full rounded-xl bg-blue-500 py-3 px-6 text-lg font-semibold transition ease-in-out active:scale-105 hover:brightness-95 md:w-auto disabled:!brightness-[40%] disabled:!scale-100 ${
    outlined
      ? "bg-transparent ring-1 ring-inset ring-zinc-800 text-zinc-300"
      : ""
  } ${customClasses} ${
    text ? "bg-transparent px-0 py-0 active:!scale-100" : ""
  } ${icon ? "bg-transparent !p-2 active:!scale-100 !w-auto" : ""}`;

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  type,
  customClasses,
  children,
  href,
  to,
  text,
  icon,
  outlined,
  ...props
}) => {
  if (text === true && icon === true)
    throw new Error("Button cannot be text and icon at the same time");

  const buttonClassName = createButtonClassName(
    customClasses as string,
    text as boolean,
    icon as boolean,
    outlined as boolean
  );

  if (typeof href !== "undefined")
    return (
      <a href={href} target="blank" className={buttonClassName}>
        {children}
      </a>
    );
  if (typeof to !== "undefined")
    return (
      <Link to={to} className={buttonClassName}>
        {children}
      </Link>
    );
  return (
    <button
      type={type as "reset" | "button" | "submit" | undefined}
      className={buttonClassName}
      {...props}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  customClasses: "",
  text: false,
  icon: false,
  outlined: false,
};

export default Button;
