import { useCookieConsentContext } from "@use-cookie-consent/react";
import React, { useEffect, useState } from "react";

import Button from "../Button";

const CookieConsent: React.FC = () => {
  const { consent, acceptCookies, declineAllCookies } =
    useCookieConsentContext();

  const [showCookieConsentBanner, setShowCookieConsentBanner] = useState(false);

  useEffect(() => {
    consent.statistics !== undefined
      ? setShowCookieConsentBanner(false)
      : setShowCookieConsentBanner(true);
  }, [consent]);

  return (
    <div
      className={`fixed inset-x-4 bottom-4 rounded-xl bg-zinc-900 px-8 py-6 md:inset-x-0 md:bottom-6 md:left-6 md:mx-0 md:w-[400px] ${
        showCookieConsentBanner ? "" : "hidden"
      }`}
    >
      <p className="text-lg font-semibold">Ta strona używa ciasteczek 🍪</p>
      <p className="mt-2 text-zinc-300">
        Wykorzystujemy ciasteczka w celu analizy w jaki sposób korzystasz z
        naszej strony
      </p>

      <div className="mt-8 flex flex-col gap-4 sm:flex-row">
        <Button
          tabIndex={0}
          customClasses="bg-zinc-100 text-base text-zinc-900 !w-full"
          onClick={() => acceptCookies({ statistics: true })}
        >
          Zaakceptuj
        </Button>
        <Button
          tabIndex={0}
          customClasses="!bg-zinc-800 text-base !w-full"
          onClick={declineAllCookies}
        >
          Odrzuć
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
